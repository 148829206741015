import React, { useEffect,useState } from 'react';
import dynamic from 'next/dynamic';

import { Meta } from '../components/Meta';

// Dynamically import components
const DynamicNavbar = dynamic(() => import('../modules/Navbar'));
const DynamicHome = dynamic(() => import('../pageContent/Home'));
const DynamicTeam = dynamic(() => import('../pageContent/Team'));
const DynamicProducts = dynamic(() => import('../pageContent/Products'));
const DynamicServices = dynamic(() => import('../pageContent/Services'));
const DynamicAbout = dynamic(() => import('../pageContent/About'));
const DynamicCooperation = dynamic(() => import('../pageContent/Cooperation'));
const DynamicFooter = dynamic(() => import('../modules/Footer'));

// Conditionally import Sidebar only if it's a mobile device
const DynamicSidebar = dynamic(() => import('../modules/Sidebar'), { ssr: false });

export default function IndexPage() {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsMobile(mediaQuery.matches);

        const handleResize = (e: { matches: boolean | ((prevState: boolean) => boolean); }) => {
            setIsMobile(e.matches);
        };

        mediaQuery.addEventListener('change', handleResize);

        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };
    }, []);

    return (
        <>
            <Meta />
            {isMobile && <DynamicSidebar isOpen={isOpen} toggle={toggle} />}
            <DynamicNavbar toggle={toggle} />
            <DynamicHome />
            <DynamicTeam />
            <DynamicProducts />
            <DynamicServices />
            <DynamicAbout />
            <DynamicCooperation />
            <DynamicFooter />
        </>
    );
}

// Uncomment if needed
// export const getServerSideProps: GetServerSideProps = async () => {
//     const ogImage = 'http://localhost:3000/api/logo?titleFirst=Grafit';
//     return {
//         props: {
//             image: ogImage
//         }
//     };
// };